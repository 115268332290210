import React, { useEffect, useState } from 'react';
import "./SignIn.scss";
import ImageLogo from "../../Assets/diamondBanner.jpg";

import { IoReload } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { Menuicons } from '../Menuicons';
import { FaSpinner } from "react-icons/fa";
import { IoDiamond } from "react-icons/io5";
import { RiAdminLine } from "react-icons/ri";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import AsroLogo from '../AsroLogo';
import { IoIosLogOut } from "react-icons/io";





const SignIn = () => {   
const Navigate=useNavigate();
const { transcript, resetTranscript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();
const [otpSent, setOtpSent] = useState(false);
const [inputValues, setInputValues] = useState({
  phone: "",
  otp: ""
});
const [Token, setToken] = useState('');
const [IsLoading, setIsLoading] = useState(false);
const [IsvalidCredentials, setIsvalidCredentials] = useState(true);
const [isComplete, setisComplete] = useState(true);
const [HeadingText, setHeadingText] = useState('');
const [FlowStyle, setFlowStyle] = useState({})
const [isHovered, setisHovered] = useState(false);
const [LoginError, setLoginError] = useState(false);
const [UserId, setUserId] = useState({});
const [isPhone, setisPhone] = useState('');
const [isReport, setisReport] = useState({});





    useEffect(() => {
      const TokenData= localStorage.getItem('Token');
      const TokenDataCusomer= localStorage.getItem('CustomerTokenCredentials');
      

if(!TokenData){
  Navigate('/login');
}
if(TokenDataCusomer){
  Navigate('/');
}


     
        const synthesis = window.speechSynthesis;
        const customScript = "Hi My Name Is Astro Guru. and, I am here, To change your Life, by providing You , Your Lucky Gemstone"; // Your custom script here
        const utterance = new SpeechSynthesisUtterance(customScript);
        synthesis.speak(utterance);
        const timeout = setTimeout(() => {
            const nameContainer = document.querySelector('.name-container');
         
      
            // Remove the containers from the body
            if(nameContainer){
              //nameContainer.remove();
              setisComplete(true) 
            }
            else{
              return true;
            }
          
            
           
           
          }, 7000);
         // Adjust the delay (in milliseconds) based on your animations
      
          return () => clearTimeout(timeout)
          ;

       
    
   
      },[Navigate]);

      const handlePhoneChange = (event) => {
        const { name, value } = event.target;
        console.log(name, value)

        setInputValues({
          ...inputValues,
          [name]: value
        });
       
    
      };
    

      const sendOTP = () => {
        // Simulate OTP sending logic
        // Set otpSent to true if OTP is sent successfully
        setOtpSent(true);
      };
    
      const handleSignin = async (e) => {
   
        e.preventDefault(); 
      
    
    
    
    
        try {
    
          setIsLoading(true);
          //
          
          const TokenData= await localStorage.getItem('Token');
       
          const url = ' https://9gems-backend-lx14hekvs-danishsaghirdanfas-projects.vercel.app/users_login';
    
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${TokenData}`
            },
            body: JSON.stringify({ "phone": inputValues.phone }), // Convert data to JSON format
          };
    
          fetch(url, requestOptions)
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json(); // Parse the JSON data
            })
            .then(data => {
              console.log(data);
              const Logindata = {
                "phone": inputValues.phone,
                
                
    
              }
              localStorage.setItem('CustomerloginCredentials', JSON.stringify(Logindata))
              setIsLoading(false)
    
              sendOTP();
    
            })
            .catch(error => {
              if(!IsvalidCredentials){
                alert("invalid credentials")
              }
          
            
              console.error('There was a problem with the fetch operation:', error);
            });
    
    
    
    
    
        
    
        } catch (error) {
          console.error('Error:', error); // Handle error
          setIsLoading(false);
          setLoginError(true); // Set login error state
        }
      };
    
    
      const handleOTPVerification = async (e) => {
        setIsLoading(true)
        e.preventDefault(); // Prevent default form submission behavior
        const data = localStorage.getItem('CustomerloginCredentials');
    
        if (data) {
          const updatedData = await JSON.parse(data);
          const userID = updatedData.userId
          setUserId(userID)
          setisPhone(updatedData.isPhone)
         
    
        }
    
    
    
    
        try {
         const TokenData= await localStorage.getItem('Token');
          const url =  'https://9gems-backend-lx14hekvs-danishsaghirdanfas-projects.vercel.app/users_otp_verification';
    
    
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${TokenData}`
            },
            body: JSON.stringify({
              "phone": inputValues.phone,
              "phoneWithCode": `91${inputValues.phone}`,
              "isPhone": data.isPhone,
              "otp":inputValues.otp
    
            }), // Convert data to JSON format
          };
    
          await fetch(url, requestOptions)
            .then(response => {
              return response.json(); // Parse the JSON data
            })
            .then(data => {
              console.log(data);
              console.log(data.user.all_reports)
              const Data=data.user.all_reports
              console.log("data Stored")
              console.log(data.token)
              
              if(Data){
                localStorage.setItem('PostData',JSON.stringify(Data));
                setIsLoading(false)
                setisReport(true) 
              }
              else{
                setisReport(false)
              }
              
              const Logindata = {
                "token": data.token,
                "masterUserId": data.user.id,
    
    
              }
             function SaveData(){
              console.log(Logindata)
                 localStorage.setItem('CustomerTokenCredentials',JSON.stringify(Logindata));
                console.log("data Stored")
                Navigate('/');
              }
              SaveData();
    
    
    
            })
            .catch(error => {
    
              console.error('There was a problem with the fetch operation:', error);
              alert("Invalid details")
            });
    
    
    
    
    
          setIsLoading(false);
    
        } catch (error) {
          console.error('Error:', error); // Handle error
          setIsLoading(false);
          setLoginError(true); // Set login error state
        }
      };
    
      const goHome = () => {
        Navigate('/')
    
    
      };
    
      const reloadPage = () => {
        window.location.reload();
      };
    

      // Define the styles for .top and .bottom
      const topBottomStyles = {
        marginLeft: isHovered ? '200px' : '0',
        transformOrigin: '-200px 50%',
        transitionDelay: '0s',
      };
    
      // Define the styles for .center
      const centerStyles = {
        opacity: isHovered ? 1 : 0,
        transitionDelay: isHovered ? '0.2s' : '0s',
      };
  const handleClick = () => {
    console.log('Container clicked');
    // Add your custom logic here
  };
 
  const handleMouse=()=>{
    setisHovered(true)
    console.log("Mouse Hovered")
  }

  const handleSpeakPhone = () => {
    const synthesis = window.speechSynthesis;
    const customScript = "Enter Your Phone Number and click on get OTP button"; // Your custom script here
    const utterance = new SpeechSynthesisUtterance(customScript);
    synthesis.speak(utterance);
  };
  const handleSpeakOTP = () => {
    const synthesis = window.speechSynthesis;
    const customScript = "Enter Your OTP and start your journey"; // Your custom script here
    const utterance = new SpeechSynthesisUtterance(customScript);
    synthesis.speak(utterance);
  };


const handleOTPHover=()=>{
    const synthesis = window.speechSynthesis;
    const customScript = ""; // Your custom script here
    const utterance = new SpeechSynthesisUtterance(customScript);
    synthesis.speak(utterance);
};

const Logout=()=>{
 
    localStorage.removeItem('Token');
  
   
    Navigate('/login')
}

  return (
    <>
     <IoReload className='reload_icon' onClick={reloadPage} />
      <IoHome className='home_icon' onClick={goHome} />
      <IoIosLogOut className='Logout_icon' onClick={Logout}/>
      {/*<RiAdminLine className='Admin_icon' onClick={()=>Navigate('/admin')}/>*/}
    
      
     


  
      <div className="Signin-container" onClick={handleClick}  style={FlowStyle}   onMouseEnter={() => setisHovered(true)}
      onMouseLeave={() => setisHovered(false)}>
        <div className="top" style={topBottomStyles}>
          <img src={ImageLogo} alt="Logo" />
        </div >
        <div className="bottom"  style={topBottomStyles}></div>
       {isComplete?
        <div className="center" style={{centerStyles}}>
          <AsroLogo/>
          <h4 className='Astroguru_heading'> AStro guru</h4>
          {otpSent?
          <div className='form_signin'>
            <form className='form_signin'>
          <input type="number" style={{color:"#000000"}} placeholder="Enter OTP" onClick={handleSpeakOTP} onChange={handlePhoneChange}  value={inputValues.otp}  name='otp'  autocomplete="off"/>
          <button type="submit" className='login_button' onClick={handleOTPVerification}> {IsLoading?<FaSpinner className='loader'/>:'Login For Report'}  </button> </form> </div>
:
<div className='form_signin'>
  <form className='form_signin'>
          <input type="number"  style={{color:"#000000"}} placeholder="Customer Phone Number" onClick={handleSpeakPhone} onChange={handlePhoneChange} value={inputValues.phone}  name='phone'  autocomplete="off"/>
          <button type="submit" className='login_button' onClick={handleSignin}> {IsLoading?<FaSpinner className='loader'/>:'Get OTP'}  </button>
          </form>
          
           </div>
          
          }

         
        </div>
        :<></>
      }
      </div>

    </>
  );
};

export default SignIn;
