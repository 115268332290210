import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Menuicons } from '../Menuicons';
import './DataPage.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Video from "../../Assets/universeVideo.mp4";
import { IoChevronBackCircleOutline, IoChevronForwardCircleOutline } from "react-icons/io5";
import AstroLogoData from '../AstroLogoData';

const DataPage = () => {
  const Navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    day: '',
    month: '',
    year: '',
    hour: '',
    minute: '',
    city: '',
    phoneNumber: '',
    countryCode: '',
    weight: '',
  });

  const [valid, setValid] = useState(true);

  useEffect(() => {
    const data = localStorage.getItem("CustomerTokenCredentials");
    if (!data) {
      Navigate('/customer_login');
    }

    const synthesis = window.speechSynthesis;
    const customScript = "I can help you to discover your lucky gemstone";
    const utterance = new SpeechSynthesisUtterance(customScript);
    synthesis.speak(utterance);
  }, [Navigate]);

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === 'phoneNumber') {
      setValid(validatePhoneNumber(value));
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    handleChange(name, value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const { day, month, year, hour, minute, weight, ...rest } = formData;
    
    const dob = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    const time = `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
    
    const weightValue = parseFloat(weight);
    const weightDivided = weightValue / 10;
    const weightFormatted = Number.isInteger(weightDivided) 
      ? weightDivided.toString() 
      : `${Math.floor(weightDivided)}+`;
  
    const dataToSave = {
      ...rest,
      dob,
      time,
    };
  
    localStorage.setItem('submittedData', JSON.stringify(dataToSave));
    localStorage.setItem('weightData', weightFormatted);
    Navigate('/DataValidate');
  };
  

  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      const value = i < 10 ? `0${i}` : i.toString();
      options.push(<option key={value} value={value}>{value}</option>);
    }
    return options;
  };


  return (
    <>
      <Menuicons />
      <div className="navigation-icons">
        <IoChevronBackCircleOutline
          className='nav-icon'
          onClick={() => { window.history.back(); }}
        />
        <IoChevronForwardCircleOutline
          className='nav-icon'
          onClick={() => { window.history.forward(); }}
        />
      </div>
      <h4 className='Astroguru_heading_Data_Page'>Astro guru</h4>
      <section style={{ height: '100vh', overflow: 'hidden' }}>
        <video src={Video} autoPlay muted loop className="fullscreen-video" />
        <div className="datapage" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="DataPage_Form" style={{ width: '100%', maxWidth: '700px', padding: '20px', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '15px', boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)' }}>
            <form onSubmit={handleSubmit} className="form-data grid-form">
              <div className="grid-container">
                <div className="grid-item">
                  <label htmlFor="name" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold' }}>Name</label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Customer Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    className='input'
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: 'none',
                      borderRadius: '15px',
                      backgroundColor: '#333',
                      color: '#fff',
                      fontSize: '14px',
                      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                    }}
                  />
                </div>
                <div className="grid-item">
                  <label htmlFor="phoneNumber" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold' }}>Mobile Number</label>
                  <PhoneInput
                    required
                    className='input'
                    name="phoneNumber"
                    country={'in'}
                    value={formData.phoneNumber}
                    onChange={(value) => handleChange("phoneNumber", value)}
                    inputStyle={{
                      backgroundColor: '#333',
                      color: '#fff',
                      paddingLeft: '45px',
                      fontSize: '14px',
                    }}
                  />
                </div>
                <div className="grid-item">
                  <label htmlFor="dob" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold' }}>Date Of Birth</label>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <select
                      name="day"
                      value={formData.day}
                      onChange={handleInputChange}
                      required
                      className="custom-input"
                      style={{
                        width: '32%',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '15px',
                        backgroundColor: '#333',
                        color: '#fff',
                        fontSize: '14px',
                        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <option value="" disabled>Day</option>
                      {generateOptions(1, 31)}
                    </select>
                    <select
                      name="month"
                      value={formData.month}
                      onChange={handleInputChange}
                      required
                      className="custom-input"
                      style={{
                        width: '32%',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '15px',
                        backgroundColor: '#333',
                        color: '#fff',
                        fontSize: '14px',
                        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <option value="" disabled>Month</option>
                      {generateOptions(1, 12)}
                    </select>
                    <input
                      type="number"
                      name="year"
                      placeholder="Year"
                      value={formData.year}
                      onChange={handleInputChange}
                      required
                      min="1920"
                      max="2100"
                      className="custom-input"
                      style={{
                        width: '36%',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '15px',
                        backgroundColor: '#333',
                        color: '#fff',
                        fontSize: '14px',
                        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                      }}
                    />
                  </div>
                </div>
                <div className="grid-item">
                  <label htmlFor="time" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold' }}>Time Of Birth</label>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <select
                      name="hour"
                      value={formData.hour}
                      onChange={handleInputChange}
                      required
                      className="custom-input"
                      style={{
                        width: '48%',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '15px',
                        backgroundColor: '#333',
                        color: '#fff',
                        fontSize: '14px',
                        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <option value="" disabled>HH</option>
                      {generateOptions(1, 24)}
                    </select>
                    <select
                      name="minute"
                      value={formData.minute}
                      onChange={handleInputChange}
                      required
                      className="custom-input"
                      style={{
                        width: '48%',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '15px',
                        backgroundColor: '#333',
                        color: '#fff',
                        fontSize: '14px',
                        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <option value="" disabled>MM</option>
                      {generateOptions(1, 60)}
                    </select>
                  </div>
                </div>
                <div className="grid-item">
                  <label htmlFor="city" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold' }}>City</label>
                  <input
                    id="city"
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                    className='input'
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: 'none',
                      borderRadius: '15px',
                      backgroundColor: '#333',
                      color: '#fff',
                      fontSize: '14px',
                      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                    }}
                  />
                </div>
                <div className="grid-item">
                  <label htmlFor="weight" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold' }}>Weight</label>
                  <input
                    id="weight"
                    type="number"
                    name="weight"
                    placeholder="Weight"
                    value={formData.weight}
                    onChange={handleInputChange}
                    required
                    className='input'
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: 'none',
                      borderRadius: '15px',
                      backgroundColor: '#333',
                      color: '#fff',
                      fontSize: '14px',
                      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                    }}
                  />
                </div>
              </div>
              <motion.button
                type="submit"
                disabled={!valid}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{
                  display: 'block',
                  width: '100%',
                  padding: '15px',
                  marginTop: '20px',
                  border: 'none',
                  borderRadius: '15px',
                  backgroundColor: '#ff4500',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  margin: '0 auto'
                }}
              >
                Submit
              </motion.button>
            </form>
          </div>
        </div>
      </section>
      <AstroLogoData />
    </>
  );
};

export default DataPage;
