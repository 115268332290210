import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Menuicons } from '../Pages/Menuicons';
import { Loading } from '../Loading';
import Logo from '../Logo';


const GemstoneValidate = () => {
    const history = useNavigate();
    const TokenData = localStorage.getItem('CustomerTokenCredentials');
    const ParsedTokenData=JSON.parse(TokenData);
    const Token=ParsedTokenData.Token
    const masterUserId=ParsedTokenData.masterUserId;
      
  useEffect(() => {
    const FetchData= async ()=>{
        
      await fetch('https://9gems-backend-lx14hekvs-danishsaghirdanfas-projects.vercel.app/users_verification', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
       'Authorization': `Bearer ${Token}`
     },
     
   })
   .then(response => {
     if (!response.ok) {
       throw new Error('Network response was not ok');
     }
     return response.json();
   })
   .then(data => {
     console.log('Response:', data);
     console.log("user successfully Authenticated")
   })
   .catch(error => {
     console.error('Error:', error);
   });
       
   }
   FetchData();
    const fetchData = async () => {
     
      try {
        const storedData = localStorage.getItem('submittedData');
        console.log(storedData)
        const parsedData = JSON.parse(storedData);
        const city=parsedData.city;
       const Name=parsedData.name;
       const DOB=parsedData.dob;
       const Time=parsedData.time;
       const Lat=parsedData.lat;
       const Long=parsedData.long;
       const Timezone=parsedData.timezone;
       const PhoneNumber=parsedData.phoneNumber
       const countryCode=parsedData.countryCode
       const XDob=DOB.split('-'); 
       const FormattedDob=`${XDob[2]}/${XDob[1]}/${XDob[0]}`;
       console.log(Time,Lat,Long,Timezone,FormattedDob);
//api.vedicastroapi.com/v3-json/extended-horoscope/gem-suggestion
 await fetch(`https://api.vedicastroapi.com/v3-json/horoscope/ascendant-report?dob=${FormattedDob}&tob=${Time}&lat=${Lat}&lon=${Long}&tz=${Timezone}&api_key=1127d29b-aaef-5396-8d1c-69c5bcfeb584`)
.then(response => {
    console.log(response)
    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }
    return response.json();
  })  
  .then(data => {
        
    console.log(data);
    const AscendantData=data.response[0].ascendant;
    const Gemstone=data.response[0].lucky_gem;
    console.log(data.response);
    console.log(AscendantData,Gemstone);
   

    
    const updatedData={
        name: Name,
        countryCode:countryCode,
        phoneNumber:PhoneNumber,
        dob: DOB,
        time: Time,
        lat: Lat,
        long: Long,
        timezone:Timezone,
        Ascendant:AscendantData,
        GemStone:Gemstone,
        GemstoneData:Gemstone,
        city:city
      
      
      // Add more values as needed
    };
    console.log(updatedData);
    console.log(updatedData.GemStone)
    localStorage.setItem('submittedData', JSON.stringify(updatedData));
   
   
   
  
   
     
   })
 
  
     
        // Redirect to the other page after fetching
    history('/ShowData');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    
   
  }, [history]);

  return (
    <>
  
   <Menuicons/>
   <Loading/>
   </>
  )
}

export default GemstoneValidate